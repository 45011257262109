import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveDeviceCustomData,
  getActivePersonCustomData,
  getArchievedDeviceCustomData,
  getArchievedPersonCustomData,
  getCurrentAppId,
  getCustomDataIsLoading,
} from 'src/selectors/current_app';
import { attributes } from 'src/actions/attributes';
import { isEmpty } from 'ramda';
import { CriteriaAttributes } from './targeting/multi-app-segment-builder/types';

export const useTargetingAttributes = (): CriteriaAttributes => {
  const appId = useSelector(getCurrentAppId);
  const activePersonCustomData = useSelector(getActivePersonCustomData);
  const activeDeviceCustomData = useSelector(getActiveDeviceCustomData);
  const archievedPersonCustomData = useSelector(getArchievedPersonCustomData);
  const archievedDeviceCustomData = useSelector(getArchievedDeviceCustomData);
  const isCustomDataLoading = useSelector(getCustomDataIsLoading);

  const dispatch = useDispatch();

  const personCustomData = useMemo(
    () => ({ ...activePersonCustomData, ...archievedPersonCustomData }),
    [activePersonCustomData, archievedPersonCustomData],
  );
  const deviceCustomData = useMemo(
    () => ({ ...activeDeviceCustomData, ...archievedDeviceCustomData }),
    [activeDeviceCustomData, archievedDeviceCustomData],
  );

  const getKeyValue = (key: string) => (key.startsWith('custom_data.') ? key : `custom_data.${key}`);
  const attributeChoices = useMemo(
    () => ({
      device: {
        app_id: appId,
        fields: Object.keys(deviceCustomData || {}).map((key) => {
          const itemToUpd = deviceCustomData[key] || {};
          return { ...itemToUpd, name: key, key: getKeyValue(key) };
        }),
        model: 'device',
        name: 'Device',
      },
      person: {
        app_id: appId,
        fields: Object.keys(personCustomData || {}).map((key) => {
          const itemToUpd = personCustomData[key] || {};
          return { ...itemToUpd, name: key, key: getKeyValue(key) };
        }),
        model: 'person',
        name: 'Person',
      },
    }),
    [appId, deviceCustomData, personCustomData],
  );

  // When changing apps, re-fetch attributes
  useEffect(() => {
    if (!isCustomDataLoading) {
      dispatch(attributes.getKeys);
    }
  }, [appId]);

  useEffect(() => {
    if (isEmpty(personCustomData) || (isEmpty(deviceCustomData) && !isCustomDataLoading)) {
      dispatch(attributes.getKeys);
    }
  }, [appId, personCustomData, deviceCustomData, isCustomDataLoading]);

  return attributeChoices;
};
