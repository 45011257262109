/* eslint-disable no-duplicate-case */
import {
  SurveyMultiAppsActionPayload,
  SurveyReducerActions,
} from 'src/actions/surveys-multi-apps/survey-multi-apps.actions';

import { SurveyMultiAppsActions } from 'src/actions/surveys-multi-apps/survey-multi-apps.action-types';

import { LoveDialogActions } from 'src/actions/love-dialog/love-dialog.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import { AppInteractionData } from 'src/types/core';

import {
  AppStats,
  InteractionDataResponseStat,
  MultiAppsSurvey,
  SurveyStatistics,
  SurveyTextAnswers,
} from './surveys-multi-apps.types';

// Implementation inspired by:
// https://medium.com/@peatiscoding/typescripts-with-redux-redux-thunk-recipe-fcce4ffca405

type SurveyId = string;

export interface ISurveysMultiAppsReduxState {
  loadingActive: boolean;
  loadingNotActive: boolean;
  bootstrapped: boolean;
  fetchActiveError?: boolean;
  fetchNotActiveError?: boolean;
  deleteError?: boolean;
  surveysActive: MultiAppsSurvey[];
  surveysNotActive: MultiAppsSurvey[];
  surveyStatistics: SurveyStatistics;
  loadingIds: SurveyId[];
  // The id of the survey whose active status is being updated
  updatingSurveyActiveId?: SurveyId;
  // The id of the survey that is being deleted
  deletingSurveyId?: SurveyId;
  // The list of appIds to which the current user is subscribed to survey summary emails
  emailSummaryAppIds?: string[];
  // The total number of active/not active surveys.
  totalActive: number;
  totalNotActive: number;
  // The last page of active/not active surveys that was fetched.
  pageActive: number;
  pageNotActive: number;
}

export const initialState: ISurveysMultiAppsReduxState = {
  loadingActive: false,
  loadingNotActive: false,
  bootstrapped: false,
  fetchActiveError: false,
  fetchNotActiveError: false,
  deleteError: false,
  surveysActive: [],
  surveysNotActive: [],
  surveyStatistics: {},
  loadingIds: [],
  totalActive: 0,
  totalNotActive: 0,
  pageActive: 0,
  pageNotActive: 0,
};

export const surveysMultiAppsReducer = (
  state: ISurveysMultiAppsReduxState = initialState,
  action: SurveyReducerActions = {} as SurveyReducerActions,
): ISurveysMultiAppsReduxState => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_PENDING:
    case UPDATE_CURRENT_APP_SUCCESS: {
      return {
        ...state,
        ...initialState,
      };
    }

    case LoveDialogActions.SAVE_LOVE_DIALOG_SUCCESS: {
      return {
        ...state,
        bootstrapped: false,
      };
    }
    case SurveyMultiAppsActions.CREATE_SURVEY_MULTI_APPS_SUCCESS: {
      const survey = action.payload as MultiAppsSurvey;
      const active = survey.interactionData.some((data) => data.active);
      return {
        ...state,
        bootstrapped: false,
        surveysActive: active ? [survey, ...state.surveysActive] : state.surveysActive,
        surveysNotActive: active ? state.surveysNotActive : [survey, ...state.surveysNotActive],
      };
    }
    case SurveyMultiAppsActions.UPDATE_SURVEY_MULTI_APPS_SUCCESS: {
      const { id, interactionData } = action.payload as { id: string; interactionData: AppInteractionData[] };
      const active = interactionData.some((data) => data.active);

      return {
        ...state,
        surveysActive: active
          ? ([action.payload, ...state.surveysActive.filter((survey) => survey.id !== id)] as MultiAppsSurvey[])
          : [...state.surveysActive.filter((survey) => survey.id !== id)],
        surveysNotActive: active
          ? [...state.surveysNotActive.filter((survey) => survey.id !== id)]
          : ([action.payload, ...state.surveysNotActive.filter((survey) => survey.id !== id)] as MultiAppsSurvey[]),
      };
    }
    case SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_PENDING: {
      const { id } = action.payload as { id: string };
      return {
        ...state,
        loadingIds: [id, ...state.loadingIds],
      };
    }
    case SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_SUCCESS: {
      const createdSurvey = action.payload as MultiAppsSurvey;
      const active = createdSurvey.interactionData ? createdSurvey.interactionData.filter((data) => data.active) : [];

      return {
        ...state,
        surveysActive: active
          ? [...state.surveysActive.filter((survey) => survey.id !== createdSurvey.id), createdSurvey]
          : state.surveysActive,
        surveysNotActive: active
          ? state.surveysNotActive
          : [...state.surveysNotActive.filter((survey) => survey.id !== createdSurvey.id), createdSurvey],
        loadingIds: [...state.loadingIds.filter((id) => id !== action.payload?.id)],
      };
    }
    case SurveyMultiAppsActions.RECEIVE_FETCH_ACTIVE_SURVEYS_MULTI_APPS: {
      const storedActiveSurveysSet = new Map();
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysActive.forEach((surv) => storedActiveSurveysSet.set(surv.id, true));
      // check if new surveys are already stored
      const newActiveSurvs = action.surveys?.filter((surv) => !storedActiveSurveysSet.has(surv.id));
      return {
        ...state,
        surveysActive: [...state.surveysActive, ...(newActiveSurvs as MultiAppsSurvey[])],
        loadingActive: false,
        bootstrapped: true,
        pageActive: action.page as number,
        totalActive: action.total as number,
        fetchActiveError: false,
      };
    }

    case SurveyMultiAppsActions.RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS: {
      const storedNotActiveSurveysSet = new Map();
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysNotActive.forEach((surv) => storedNotActiveSurveysSet.set(surv.id, true));
      // check if new surveys are already stored
      const newNotActiveSurvs = action.surveys?.filter((surv) => !storedNotActiveSurveysSet.has(surv.id));
      return {
        ...state,
        surveysNotActive: [...state.surveysNotActive, ...(newNotActiveSurvs as MultiAppsSurvey[])],
        loadingNotActive: false,
        bootstrapped: true,
        pageNotActive: action.page as number,
        totalNotActive: action.total as number,
        fetchNotActiveError: false,
      };
    }

    case SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_ERROR: {
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => id !== action.payload?.id),
      };
    }

    case SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_PENDING: {
      const { ids } = action.payload as { ids: string[] };

      return {
        ...state,
        loadingIds: [...state.loadingIds, ...ids],
      };
    }
    case SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_SUCCESS: {
      const survsIdItemMap = new Map();
      Array.isArray(action.payload) &&
        action.payload.forEach((survey: MultiAppsSurvey) => survsIdItemMap.set(survey.id, survey));
      return {
        ...state,
        surveysActive: state.surveysActive.map((survey) =>
          survsIdItemMap.has(survey.id) ? survsIdItemMap.get(survey.id) : survey,
        ),
        surveysNotActive: state.surveysNotActive.map((survey) =>
          survsIdItemMap.has(survey.id) ? survsIdItemMap.get(survey.id) : survey,
        ),
        loadingIds: [...state.loadingIds.filter((id) => !survsIdItemMap.has(id))],
      };
    }
    case SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_ERROR: {
      const surveysIdMap = new Map();
      if (action.payload && Array.isArray(action.payload.ids)) {
        action.payload.ids.forEach((id: string) => surveysIdMap.set(id, true));
      }
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => !surveysIdMap.has(id)),
      };
    }

    case SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_SUCCESS: {
      const { surveyId, stats, apps } = action.payload as {
        surveyId: string;
        stats: InteractionDataResponseStat[];
        apps: string[];
      };

      const getStatsByApp = (app: string) => stats.find((stat) => stat.app_id === app)?.stats || [];

      const data: AppStats = apps.reduce((appStatsObj: AppStats, app: string) => {
        appStatsObj[app] = { stats: getStatsByApp(app) };
        return appStatsObj;
      }, {});

      return {
        ...state,
        surveyStatistics: {
          ...state.surveyStatistics,
          [surveyId]: {
            ...state.surveyStatistics[surveyId],
            data,
          },
        },
      };
    }
    case SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_ERROR: {
      const { surveyId } = action.payload as { surveyId: string };
      const surveyStats = state.surveyStatistics ? { ...state.surveyStatistics } : {};
      const statistics = surveyStats[surveyId];
      if (statistics) {
        statistics.data = {};
      }
      return {
        ...state,
        surveyStatistics: surveyStats,
      };
    }

    case SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_SUCCESS:
    case SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_SUCCESS: {
      const { surveyId, textAnswers, apps } = action.payload as {
        surveyId: string;
        textAnswers: Record<string, SurveyTextAnswers>;
        apps: string[];
      };

      const getQuestionTextAnswerByApp = (app: string, questionTextAnswer: SurveyTextAnswers) => {
        const filteredAnswers = questionTextAnswer.answers.filter((answer) => answer.app_id === app);
        return { ...questionTextAnswer, answers: filteredAnswers };
      };

      const getTextAnswersByApp = (app: string) => {
        const updatedTextAnswers: Record<string, SurveyTextAnswers> = {};

        Object.keys(textAnswers).forEach((questionId) => {
          updatedTextAnswers[questionId] = getQuestionTextAnswerByApp(app, textAnswers[questionId]);
        });

        return updatedTextAnswers;
      };

      const data: AppStats = apps.reduce((appStatsObj: AppStats, app: string) => {
        appStatsObj[app] = { ...state.surveyStatistics[surveyId]?.data?.[app], textAnswers: getTextAnswersByApp(app) };
        return appStatsObj;
      }, {});

      return {
        ...state,
        surveyStatistics: {
          ...state.surveyStatistics,
          [surveyId]: {
            ...state.surveyStatistics[surveyId],
            data,
          },
        },
      };
    }

    case SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_ERROR:
    case SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_ERROR: {
      return state;
    }

    case SurveyMultiAppsActions.REQUEST_FETCH_ACTIVE_SURVEYS_MULTI_APPS:
      return { ...state, loadingActive: true };
    case SurveyMultiAppsActions.REQUEST_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS:
      return { ...state, loadingNotActive: true };

    case SurveyMultiAppsActions.RECEIVE_FETCH_ACTIVE_SURVEYS_MULTI_APPS:
      const storedActiveSurveysSet = new Map();
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysActive.forEach((surv) => storedActiveSurveysSet.set(surv.id, true));
      // check if new surveys are already stored
      const newActiveSurvs = action.surveys?.filter((surv) => !storedActiveSurveysSet.has(surv.id));
      return {
        ...state,
        surveysActive: [...state.surveysActive, ...(newActiveSurvs as MultiAppsSurvey[])],
        loadingActive: false,
        bootstrapped: true,
        pageActive: action.page as number,
        totalActive: action.total as number,
        fetchActiveError: false,
      };

    case SurveyMultiAppsActions.RECEIVE_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS:
      const storedNotActiveSurveysSet = new Map();
      // create map of every survey_id which is already stored and use it to avoid dublicates
      state.surveysNotActive.forEach((surv) => storedNotActiveSurveysSet.set(surv.id, true));
      // check if new surveys are already stored
      const newNotActiveSurvs = action.surveys?.filter((surv) => !storedNotActiveSurveysSet.has(surv.id));
      return {
        ...state,
        surveysNotActive: [...state.surveysNotActive, ...(newNotActiveSurvs as MultiAppsSurvey[])],
        loadingNotActive: false,
        bootstrapped: true,
        pageNotActive: action.page as number,
        totalNotActive: action.total as number,
        fetchNotActiveError: false,
      };

    case SurveyMultiAppsActions.ERRORED_FETCH_ACTIVE_SURVEYS_MULTI_APPS:
      return {
        ...state,
        loadingActive: false,
        fetchActiveError: true,
        surveysActive: [],
      };

    case SurveyMultiAppsActions.ERRORED_FETCH_NOT_ACTIVE_SURVEYS_MULTI_APPS:
      return {
        ...state,
        loadingNotActive: false,
        fetchNotActiveError: true,
        surveysNotActive: [],
      };

    case SurveyMultiAppsActions.REQUEST_UPDATE_SURVEY_MULTI_APPS_ACTIVE:
      return {
        ...state,
        updatingSurveyActiveId: action.surveyId,
      };

    case SurveyMultiAppsActions.RECEIVE_UPDATE_SURVEY_MULTI_APPS_ACTIVE: {
      const { surveyId, active, interactionData } = action.payload as SurveyMultiAppsActionPayload;
      const updatedSurvey = [...state.surveysNotActive, ...state.surveysActive].find((s) => s.id === surveyId);
      const isAllInteractionDataInActive = interactionData?.every((int) => !int.active);
      const isSomeInteractionDataActive = interactionData?.some((int) => int.active);
      const shouldBeMovedToActive = !active && isSomeInteractionDataActive;
      const shouldBeMovedToNotActive = active && isAllInteractionDataInActive;

      let surveysActive = [...state.surveysActive];
      let surveysNotActive = [...state.surveysNotActive];

      if (shouldBeMovedToNotActive) {
        surveysActive = state.surveysActive.filter((s) => s.id !== surveyId);
        surveysNotActive = [
          ...state.surveysNotActive.filter((s) => s.id !== surveyId),
          { ...updatedSurvey, interactionData } as MultiAppsSurvey,
        ];
      }

      if (shouldBeMovedToActive) {
        surveysActive = [
          ...state.surveysActive.filter((s) => s.id !== surveyId),
          { ...updatedSurvey, interactionData } as MultiAppsSurvey,
        ];
        surveysNotActive = state.surveysNotActive.filter((s) => s.id !== surveyId);
      }

      if (!shouldBeMovedToActive && !shouldBeMovedToNotActive) {
        if (active) {
          surveysActive = [
            ...state.surveysActive.filter((s) => s.id !== surveyId),
            { ...updatedSurvey, interactionData } as MultiAppsSurvey,
          ];
        } else {
          surveysNotActive = [
            ...state.surveysNotActive.filter((s) => s.id !== surveyId),
            { ...updatedSurvey, interactionData } as MultiAppsSurvey,
          ];
        }
      }

      return {
        ...state,
        updatingSurveyActiveId: '',
        surveysActive,
        surveysNotActive,
      };
    }

    case SurveyMultiAppsActions.REQUEST_DELETE_SURVEY_MULTI_APPS:
      return { ...state, deletingSurveyId: action.surveyId, deleteError: false };

    case SurveyMultiAppsActions.RECEIVE_DELETE_SURVEY_MULTI_APPS:
      return {
        ...state,
        bootstrapped: false,
        surveysActive: (state.surveysActive || []).filter((survey) => survey.id !== action.surveyId),
        surveysNotActive: (state.surveysNotActive || []).filter((survey) => survey.id !== action.surveyId),
        deletingSurveyId: undefined,
        deleteError: false,
      };
    case SurveyMultiAppsActions.ERRORED_DELETE_SURVEY_MULTI_APPS:
      return { ...state, deletingSurveyId: undefined, deleteError: true };

    case SurveyMultiAppsActions.RECEIVE_SURVEY_MULTI_APPS_EMAIL_SUMMARY_APP_IDS:
      return { ...state, emailSummaryAppIds: action.emailSummaryAppIds };

    default:
      return state;
  }
};
