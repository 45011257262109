import React from 'react';
import { AppInteractionData, CriteriaTargeting, InteractionType } from 'src/types/core';
import { OrganizationApp } from 'src/reducers/organizations';
import { TargetingAppsSection } from './targeting-apps-section';
import { CriteriaAttributes } from './multi-app-segment-builder/types';

interface ImtTargetingProps {
  apps: OrganizationApp[];
  interactionData: AppInteractionData[];
  type: InteractionType;
  startTime?: string | null;
  selfTargeting?: boolean;
  interactions?: CriteriaTargeting;
  attributes?: CriteriaAttributes;
  children: React.ReactNode;
}

export const ImtTargeting: React.FC<ImtTargetingProps> = ({
  apps = [],
  interactionData = [],
  type,
  startTime,
  selfTargeting,
  interactions,
  attributes,
  children,
}) => {
  return (
    <>
      <TargetingAppsSection
        apps={apps}
        interactionData={interactionData}
        selfTargeting={selfTargeting}
        type={type}
        startTime={startTime}
        interactions={interactions}
        attributes={attributes}
      />
      {children}
    </>
  );
};
