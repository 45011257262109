/* eslint-disable react-hooks/rules-of-hooks */

import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { isEmpty } from 'ramda';
import { LockerInfo, BannerInfo } from 'src/interactions/newinteractions/common';
import { InteractionSection } from 'src/interactions/newinteractions/creation';
import { FanSignalTemplateName } from 'src/reducers/surveys-multi-apps/survey-multi-apps-templates';
import { useSurveyMultiAppsContext } from './survey-multi-apps-context';

export interface SurveyLockedBannerProps {
  section: InteractionSection;
}

export const SurveyLockedBanner: React.FC<SurveyLockedBannerProps> = ({ section }) => {
  const { hasResponses, survey } = useSurveyMultiAppsContext();
  const location = useLocation();
  const isTriggeredBy = !isEmpty(survey?.interactionData[0]?.triggeredBy || []);

  const fromSpecialTemplate = useMemo(() => {
    const name = new URLSearchParams(location.search).get('template');
    return {
      isLostFan: name === FanSignalTemplateName.LostFan,
    };
  }, [location.search]);

  if (fromSpecialTemplate.isLostFan) {
    return (
      <BannerInfo
        text="
          This survey is created with predefined example content and Shifted to Risk rule in targeting.
          Both content and targeting can be changed to your needs.
        "
      />
    );
  }

  if (section === InteractionSection.Targeting && isTriggeredBy) {
    return (
      <LockerInfo
        className="survey-locked-banner"
        text="To change the targeting method, first unlink this survey from all other interactions."
      />
    );
  }

  return !hasResponses ? null : (
    <LockerInfo
      className="survey-locked-banner"
      text="Editing the survey is restricted because responses have been collected."
    />
  );
};
