import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { bemPrefix } from 'src/utils';
import { AppInteractionData, InteractionType, Platform, SdkVersion, SelectedApps } from 'src/types/core';
import { InputCheckbox, Button, DropDown, Icon } from 'src/components/molecules';
import { sortBy, SortOrder } from 'src/utils/sort';
import { OrganizationApp } from 'src/reducers/organizations';
import { InteractionSection } from 'src/interactions/newinteractions/creation/interaction-steps';
import { getExtendedOrgApp } from './imt-apps-utils';
import { SurveyLockedBanner } from '../survey-multi-apps-form/survey-multi-apps-locked-banner';

import './imt-apps.scss';

const bem = bemPrefix('multi-apps');

const ALL_PLATFORMS_OPTION = 'All';
interface ImtAppsProps {
  appId: string;
  type: InteractionType;
  platform: Platform;
  interactionData: AppInteractionData[];
  formRef?: React.RefObject<HTMLFormElement>;
  orgName: string;
  organizationApps: OrganizationApp[];
  interactionSDKVersion: SdkVersion | undefined;
  isNewInteraction: boolean;
  hasResponses?: boolean;
  selectApps: (selectedApps: SelectedApps[]) => void;
}

const selectedOptions = [
  { value: ALL_PLATFORMS_OPTION, label: ALL_PLATFORMS_OPTION },
  { value: Platform.iOS, label: 'iOS Apps' },
  { value: Platform.Android, label: 'Android Apps' },
  { value: Platform.Web, label: 'Websites' },
];

const columnTitles = [
  { title: 'App Name', key: 'title', sortable: true },
  { title: 'Type', key: 'platform', sortable: true },
  { title: 'Monthly Active Users', key: 'mau', sortable: true },
  { title: 'Latest SDK Version', key: 'latestSDK', sortable: false },
];

export const ImtApps: React.FC<ImtAppsProps> = ({
  appId = '',
  type,
  platform,
  interactionData = [],
  organizationApps,
  orgName,
  interactionSDKVersion,
  isNewInteraction = false,
  hasResponses = false,
  selectApps,
}) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof OrganizationApp; direction: SortOrder }>({
    key: 'title',
    direction: SortOrder.asc,
  });

  const currentApp = {
    appId,
    platform,
  };

  useEffect(() => {
    const platforms = [Platform.Android, Platform.iOS, Platform.Web];
    let updatedSelectedPlatforms = [...selectedPlatforms];

    const isSelectAllAppsForOrg = organizationApps.length === interactionData.length;
    if (isSelectAllAppsForOrg && !updatedSelectedPlatforms.includes(ALL_PLATFORMS_OPTION)) {
      updatedSelectedPlatforms.push(ALL_PLATFORMS_OPTION);
    }
    if (!isSelectAllAppsForOrg && updatedSelectedPlatforms.includes(ALL_PLATFORMS_OPTION)) {
      updatedSelectedPlatforms = updatedSelectedPlatforms.filter((item) => item !== ALL_PLATFORMS_OPTION);
    }

    platforms.forEach((platform) => {
      const orgAppsByPlatform = organizationApps.filter((app) => app.platform === platform) || [];
      const interactionDataAppsByPlatform = interactionData.filter((app) => app.platform === platform);

      const isPlatformSelected = updatedSelectedPlatforms.includes(platform);

      if (orgAppsByPlatform.length === interactionDataAppsByPlatform.length && !isPlatformSelected) {
        updatedSelectedPlatforms.push(platform);
      }
      if (orgAppsByPlatform.length !== interactionDataAppsByPlatform.length && isPlatformSelected) {
        updatedSelectedPlatforms = updatedSelectedPlatforms.filter((item) => item !== platform);
      }
    });

    setSelectedPlatforms(updatedSelectedPlatforms);
  }, [interactionData, organizationApps]);

  const dataApps = organizationApps.map((app) => getExtendedOrgApp(app, interactionSDKVersion));

  useEffect(() => {
    const isEmptyInteractionData = !interactionData.length || interactionData.some(({ appId }) => !appId);
    if (isEmptyInteractionData && isNewInteraction) {
      selectApps([currentApp]);
    }
  }, []);

  const onAppSelect = useCallback(
    (app: OrganizationApp) => {
      const isSelected = interactionData.some((interaction) => interaction.appId === app.id);
      let updatedSelectedApps: SelectedApps[] = interactionData.map(({ appId, platform }) => ({ appId, platform }));

      if (isSelected) {
        updatedSelectedApps = updatedSelectedApps.filter((interaction) => interaction.appId !== app.id);
      }
      if (!isSelected) {
        updatedSelectedApps = [...updatedSelectedApps, { appId: app.id, platform: app.platform }];
      }
      selectApps(updatedSelectedApps);
    },
    [interactionData],
  );

  const disabledCheckBox = (app: OrganizationApp) => hasResponses || (isNewInteraction && app.id === appId);

  const disabledClearBtn =
    (isNewInteraction && interactionData.length === 1) || interactionData.length === 0 || hasResponses
      ? 'disabled'
      : '';

  const onSelectAll = useCallback(
    (value: string) => {
      let newSelectedPlatforms: string[] = selectedPlatforms;

      const isAllSelected = selectedPlatforms.includes(ALL_PLATFORMS_OPTION);
      const isValueSelected = selectedPlatforms.includes(value);

      if (value === ALL_PLATFORMS_OPTION) {
        newSelectedPlatforms = isAllSelected ? [] : [ALL_PLATFORMS_OPTION];
      }

      if (value !== ALL_PLATFORMS_OPTION && isValueSelected) {
        newSelectedPlatforms = newSelectedPlatforms.filter(
          (platform) => platform !== value && platform !== ALL_PLATFORMS_OPTION,
        );
      }

      if (value !== ALL_PLATFORMS_OPTION && !isValueSelected) {
        newSelectedPlatforms = newSelectedPlatforms
          .filter((platform) => platform !== ALL_PLATFORMS_OPTION)
          .concat(value);
      }

      setSelectedPlatforms(newSelectedPlatforms);

      let appsToSelect = organizationApps;
      const hasSelectedAll = newSelectedPlatforms.includes(ALL_PLATFORMS_OPTION);

      if (!hasSelectedAll) {
        appsToSelect = organizationApps.filter((app) => newSelectedPlatforms.includes(app.platform));
      }

      const currentApp = organizationApps.find((app) => app.id === appId);
      if (currentApp && isNewInteraction) {
        const isAppSelected = appsToSelect.some((app) => app.id === appId);

        if (!hasSelectedAll && !isAppSelected && newSelectedPlatforms.length === 0) {
          appsToSelect = [currentApp];
        }

        if (!isAppSelected && (hasSelectedAll || newSelectedPlatforms.length > 0)) {
          appsToSelect = isNewInteraction ? [currentApp, ...appsToSelect] : appsToSelect;
        }
      }

      const selectedAppsData: SelectedApps[] = appsToSelect.map((app) => ({
        appId: app.id,
        platform: app.platform,
      }));

      selectApps(selectedAppsData);
    },
    [organizationApps, appId, selectedPlatforms],
  );

  const onClearSelection = useCallback(() => {
    setSelectedPlatforms([]);
    selectApps(isNewInteraction ? [currentApp] : []);
  }, [isNewInteraction, currentApp]);

  const onSortClick = (key: keyof OrganizationApp) => {
    if (!columnTitles.find((column) => column.key === key)?.sortable) {
      return;
    }
    let direction: SortOrder = SortOrder.asc;
    if (sortConfig && sortConfig.key === key && sortConfig.direction === SortOrder.asc) {
      direction = SortOrder.desc;
    }
    setSortConfig({ key, direction });
  };

  const sortedApps = useMemo(() => {
    return sortBy(dataApps, sortConfig.key, sortConfig.direction);
  }, [dataApps, sortConfig]);

  const interactionTypeText = type === InteractionType.Survey ? 'survey' : 'prompt';

  const isWarningVersionDisclaimer = useMemo(() => dataApps.some((app) => app.isWarningVersion), [dataApps]);

  return (
    <div className={bem()}>
      <SurveyLockedBanner section={InteractionSection.Apps} />
      <h2 className={bem('title')}>{`${isNewInteraction ? 'Choose' : 'Manage'}`} Apps</h2>
      <p className={bem('description')}>
        Choose apps that will see the {interactionTypeText}. You&apos;ll be able to set the targeting conditions for all
        the apps later.
      </p>
      <div className={bem('header')}>
        <span className={bem('group-name')}>{orgName}</span>
        <div className={bem('select-dropdown', hasResponses ? 'disabled' : '')}>
          <DropDown
            placeholder="Select apps..."
            options={selectedOptions}
            onSelect={onSelectAll}
            selectedValue={selectedPlatforms}
            disabled={hasResponses}
            customIcon="arrowDown"
            customCloseDropdown
          />
        </div>
      </div>
      <table className={bem('table')}>
        <thead>
          <tr>
            {columnTitles.map((column) => (
              <th key={column.key}>
                <div
                  className={classNames(bem('table-title'), { [bem('table-title', 'sortable')]: column.sortable })}
                  onClick={() => onSortClick(column.key as keyof OrganizationApp)}
                >
                  {column.title}
                  {column.sortable && (
                    <Icon
                      name="arrowDown"
                      className={classNames(bem('sort-icon'), {
                        [bem('sort-icon', 'active-asc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.asc,
                        [bem('sort-icon', 'active-desc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.desc,
                      })}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedApps.map((app) => (
            <tr key={app.id}>
              <td className={bem('select-apps')}>
                <InputCheckbox
                  className={bem('input-checkbox', hasResponses ? 'disabled' : '')}
                  name="chooseApps"
                  onChange={() => onAppSelect(app)}
                  checked={interactionData.some((data) => data.appId === app.id)}
                  disabled={disabledCheckBox(app)}
                />
                <div className={bem('app-name')}>
                  <img className={bem('app-name', 'icon')} src={app.icon_url || app.iconUrl} alt={app.title} />
                  <span className={bem('app-name', 'title')}>{app.title}</span>
                </div>
              </td>
              <td>{app.platform}</td>
              <td>{app.mau === -1 ? 'N/A' : app.mau}</td>
              <td className={bem(app.isWarningVersion ? 'warning' : '')}>
                {app.platform === Platform.Web ? 'N/A' : app.latestSDKVersion || '--'}
                {app.isWarningVersion && '*'}
              </td>
            </tr>
          ))}
        </tbody>
        {isWarningVersionDisclaimer && (
          <tfoot>
            <tr>
              <td colSpan={4} className={bem('warning')}>
                *This {interactionTypeText} uses features only available for SDK version {interactionSDKVersion} or
                greater. The {interactionTypeText} will not be visible for this application.
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      <div className={bem('clear-selection', disabledClearBtn)}>
        <Button onClick={onClearSelection} disabled={!!disabledClearBtn}>
          Clear Selection
        </Button>
      </div>
    </div>
  );
};

ImtApps.displayName = 'ImtApps';
